  import { useState, useEffect, useRef } from 'react';
  import logo from '../assets/logo/logo.svg';
  import './Navbar.css';
  import { Link, useNavigate } from 'react-router-dom';
  import { HashLink } from 'react-router-hash-link';


  const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    const menuRef = useRef(null); 

    useEffect(() => {
      const handleScroll = () => {
        setScrollPosition(window.scrollY);
  
        // Close the menu if the user scrolls while it's open
        setIsMenuOpen((prevMenuOpen) => {
          if (prevMenuOpen) {
            return false;
          }
          return prevMenuOpen;
        });
      };
  
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsMenuOpen(false); // Close menu when clicking outside
        }
      };
  
      // Add event listeners for scroll and resize
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
      document.addEventListener('mousedown', handleClickOutside);
  
      // Cleanup event listeners on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
        document.addEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    const closeMenuOnClick = () => {
      if (windowWidth < 768) {
        setIsMenuOpen(false); // Close the menu on mobile devices when a link is clicked
      }
    };

    // Calculate opacity and blur based on scroll position
    const opacity = Math.max(1 - scrollPosition / 500, 0.1); // Start from 1 and decrease
    const blur = Math.min(scrollPosition / 100, 5); // Maximum blur of 5px
    const logoBlur = blur * 0.3; // Apply half the blur to the logo

    // Disable navbar interaction if opacity is greater than 0.4
    const disableInteractions = opacity < 0.7;

    // Apply styles dynamically
    const navbarStyles = {
      opacity: opacity,
      backdropFilter: `blur(${blur}px)`,
      WebkitBackdropFilter: `blur(${blur}px)`, // For Safari support
      pointerEvents: disableInteractions ? 'none' : 'auto', // Disable interactions when opacity < 0.7
    };

    const logoStyles = {
      filter: `blur(${logoBlur}px)`,
    };

    // Condition to hide hamburger based on window width
    const shouldHideHamburger = windowWidth < 768 && scrollPosition > 0;

    // Define the onClick handler for logo
    const handleLogoClick = () => {
      navigate('/'); // Navigate to the home page
    };

    return (
      <nav className={`navbar`} style={navbarStyles} ref={menuRef}>
        {/* Logo with reduced blur */}
        <img src={logo} 
             alt="Brand Logo"
             className="logo" 
               style={{ ...logoStyles, cursor: 'pointer' }} 
             onClick={handleLogoClick}/>      
        {/* Hamburger menu - only visible on small screens when not scrolled */}
        {!shouldHideHamburger && (
          <div className={`hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}

        {/* Navigation links */}
        <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`} 
            style={{ filter: `blur(${blur}px)` }}>
          <li><Link to="/#home" onClick={closeMenuOnClick}>Home</Link></li>
          <li><HashLink to="/#about" onClick={closeMenuOnClick}>About</HashLink></li>
          <li><HashLink to="/investors#investorRelations" onClick={closeMenuOnClick}>Investors</HashLink></li>
          <li><HashLink to="/#contact" onClick={closeMenuOnClick}>Contact</HashLink></li> 
        </ul> 
      </nav>
    );
  };

  export default Navbar;
