import './CoverAnimation.css'; 

const CoverAnimation = () => {

    return(
        <div className="banner-text">
                <div className="banner-text-line">Revolutionize Your Business with Our Services</div>
                <div className="banner-text-line">Trust Us for Better and Faster Solutions</div>
                <div className="banner-text-line">Everything You Need Under One Roof</div>
                <div className="banner-text-line">So You Can Focus on Growing Your Business!</div>
        </div>
    )
};

export default CoverAnimation;