import { useEffect } from 'react';

function useLazyLoad() {
  useEffect(() => {
    const lazyImages = document.querySelectorAll('.lazy-load');

    if ('IntersectionObserver' in window) {
      const lazyObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = img.dataset.src; // Load the high-res image
            img.classList.add('lazy-loaded'); // Add class for fade-in effect
            observer.unobserve(img);
          }
        });
      });

      lazyImages.forEach((img) => {
        lazyObserver.observe(img);
      });
    } else {
      // Fallback for older browsers
      lazyImages.forEach((img) => {
        img.src = img.dataset.src;
        img.classList.add('lazy-loaded');
      });
    }
  }, []);
}

export default useLazyLoad;
