import { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import '../contact/contactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [status, setStatus] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (status) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    return () => document.body.classList.remove('modal-open');
  }, [status]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      setStatus('Please complete the reCAPTCHA verification.');
      return;
    }

    const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;

    const emailParams = {
      ...formData,
      'g-recaptcha-response': recaptchaToken, // Use the reCAPTCHA token obtained from onChange
    };
    
    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, emailParams, USER_ID)
      .then(
        (result) => {
          setStatus('Message sent successfully!');
          setFormData({ name: '', email: '', message: '' });
          setRecaptchaToken(null);
          setRefreshKey((prevKey) => prevKey + 1);  
        },
        (error) => {
          setStatus('Failed to send message. Please try again later.');
          console.error(error.text);
        }
      );

  };

  const closePopup = () => setStatus('');

  return (
    <div id="contact" className="contact-form-container" key={refreshKey}>
      <h2>Contact Us</h2>
      <div className="message">
        Hey! Let us know how we can help and we’ll respond shortly.
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name*"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email*"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <textarea
          name="message"
          placeholder="How can we help you?*"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>

        {/* reCAPTCHA Component inside recaptcha-container */}
        <div className="recaptcha-container">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={(token) => setRecaptchaToken(token)}
          />
        </div>
        
        <button type="submit" className='line-button'>
          Send Email
        </button>
      </form>

      {/* Status Modal Popup */}
      {status && (
        <div className="status-popup">
          <div className="status-message">
            <p>{status}</p>
            <button onClick={closePopup} className="close-button">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
