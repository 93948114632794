import { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import './investorRel.css';

const InvestorRelations = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [status, setStatus] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (status) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    return () => document.body.classList.remove('modal-open');
  }, [status]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      setStatus('Please complete the reCAPTCHA verification.');
      return;
    }

    // Phone number validation using regex (optional)
    const phoneRegex = /^\+?\d{1,4}[\s-]?\(?\d{1,4}\)?[\s-]?\d{1,4}[\s-]?\d{1,4}$/;
    if (formData.phone && !phoneRegex.test(formData.phone)) {  // Only validate if phone is provided
      setStatus('Please enter a valid phone number.');
      return;
    }

    // Append '+' to the phone number if it's provided (e.g., for international numbers)
    let phone = formData.phone;
    if (phone && !phone.startsWith('+')) {
      phone = '+' + phone; // Prepend the '+' if it's not already there
    }

    const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;

    const emailParams = {
      ...formData,
      phone: phone, // Use the updated phone number with the '+' sign
      'g-recaptcha-response': recaptchaToken, // Use the reCAPTCHA token obtained from onChange
    };

    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, emailParams, USER_ID)
      .then(
        (result) => {
          setStatus('Message sent successfully!');
          setFormData({ name: '', email: '', phone: '', message: '' });
          setRecaptchaToken(null);
          setRefreshKey((prevKey) => prevKey + 1);
        },
        (error) => {
          setStatus('Failed to send message. Please try again later.');
          console.error(error.text);
        }
      );
  };
  
  const closePopup = () => setStatus('');

  return (
    <div className="investor-relations" id="investorRelations">
      <h1>Investor Relations</h1>
      <p>
        Welcome to the Investor Relations page of <i>Capitaflow</i>.
        We are committed to fostering innovation and delivering value for our stakeholders. 
        We believe in transparency and collaboration, values that guide us as we work to empower businesses through cutting-edge solutions. 
        This platform provides access to our latest updates, reports, and investor resources to keep you informed about our journey and opportunities for partnership.
        Thank you for your interest in Capitaflow. Together, let’s drive innovation and build a future of limitless possibilities.
      </p>
      <div className="investor-relation-form">
        <div className="left-section">
          <h2>Our Vision</h2>
          <p>
          At <i>Capitaflow</i>, we envision a world where technology transcends traditional business barriers, revolutionizing how organizations operate. 
          Our mission is to craft a business suite that transforms cumbersome processes into seamless, automated workflows.
          We are committed to replacing reliance on paper and outdated manual tools with intelligent, tech-driven solutions.
          Our approach accelerates workflows, turning hours of effort into mere seconds of precision. This focus ensures businesses can allocate resources effectively, empowering decision-making and driving success.
          To achieve this, we dedicate ourselves to creating best-in-class products, leveraging cutting-edge innovations in AI, ML, and process automation. 
          With your support, we aim to bring this vision to life and redefine the future of business management.
          </p>
        </div>
        <div key={refreshKey} className="submissionForm">
          <div>
            <h2>For Investment Related Queries</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name*"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email*"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone (Optional)"
              value={formData.phone}
              onChange={handleChange}
            />
            <textarea
              name="message"
              placeholder="Why are you interested in investing and what are your expectations from us?*"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>

            {/* reCAPTCHA Component inside recaptcha-container */}
            <div className="recaptcha-container">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={(token) => setRecaptchaToken(token)}
              />
            </div>

            <button type="submit" className='line-button'>
              Send Email
            </button>
          </form>

          {/* Status Modal Popup */}
          {status && (
            <div className="status-popup">
              <div className="status-message">
                <p>{status}</p>
                <button onClick={closePopup} className="close-button">Close</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestorRelations;
