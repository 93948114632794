import { useEffect } from 'react';
import logo from '../../assets/logo/logo.svg';
import './Animation.css';

function Animation({ onAnimationEnd }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onAnimationEnd(); // Notify parent to end animation
    }, 5000); // 5 seconds duration

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [onAnimationEnd]);

  return (
    <div className="animation-overlay">
      <img src={logo} alt="CAPITAFLOW" className="animation-logo" />
      <div className="animation-text">a suite for your businees needs...</div>
    </div>
  );
}

export default Animation;
