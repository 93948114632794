import React, { useEffect } from 'react';
import "./tnc.css"; // Add your styles for the modal

const TermsAndConditions = ({ isOpen, onClose }) => {
  const effectiveDate = 'November 25, 2024';

    // Disable body scroll when modal is open
    useEffect(() => {
      if (isOpen) {
        document.body.style.overflow = 'hidden'; // Disable scroll
      } else {
        document.body.style.overflow = ''; // Enable scroll
      }
  
      // Cleanup when modal is closed
      return () => {
        document.body.style.overflow = ''; // Reset on unmount
      };
    }, [isOpen]);

  if (!isOpen) return null; // Don't render modal if not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>&times;</button>
        <h1>Terms and Conditions</h1>
        <p><strong>Effective Date:</strong> {effectiveDate}</p>
        <p>
            Welcome to CapitaFlow Fintech Pvt. Ltd. ("CapitaFlow," "we," "our," or "us"). These Terms and Conditions ("Terms") govern your use of our website, services, and any related interactions. 
            By accessing or using our services, you agree to comply with these Terms. If you do not agree, please refrain from using our website or services.
        </p>
        
        <h2>1. Acceptance of Terms</h2>
        <p>
            By accessing or using our website or services, you affirm that you are at least 18 years old or have parental/guardian consent to use our services. Your use of our services signifies your agreement to these Terms and our Privacy Policy.
        </p>

        <h2>2. Intellectual Property</h2>
        <p>
            All content on this website, including but not limited to text, images, graphics, logos, videos, software, trademarks, service marks, and designs, is the intellectual property of CapitaFlow or its licensors and is protected under applicable intellectual property laws.
        </p>
        <ul>
            <li>You may not copy, reproduce, distribute, publish, display, or create derivative works from any part of our website or services without explicit written consent from CapitaFlow.</li>
            <li>Any unauthorized use of our content, including scraping, data mining, or unauthorized sharing, is strictly prohibited and may result in legal action.</li>
            <li>You are granted a limited, non-exclusive, and revocable license to use our services for personal, non-commercial purposes.</li>
        </ul>

        <h2>3. Third-Party Services</h2>
        <p>
            We may rely on third-party service providers to facilitate our business operations, including payment processing, hosting, and analytics. While we carefully select reputable partners, we cannot guarantee the performance, availability, or security of their services.
        </p>
        <ul>
            <li>CapitaFlow shall not be held responsible for any failures, disruptions, or damages caused by third-party service providers.</li>
            <li>In the event of an issue with a third-party provider, we will make reasonable efforts to mitigate the impact and restore services. However, we cannot guarantee resolution timelines or outcomes.</li>
        </ul>

        <h2>4. Technical Failures and Limitations</h2>
        <p>
            CapitaFlow strives to maintain uninterrupted and error-free services. However, certain circumstances beyond our control may lead to disruptions. These include, but are not limited to:
        </p>
        <ul>
            <li>Natural disasters, cyberattacks, or other force majeure events.</li>
            <li>Technical failures, including server downtime, software bugs, or network outages.</li>
            <li>Disruptions caused by third-party service providers or partners.</li>
        </ul>
        <p>
            CapitaFlow shall not be held liable for any direct, indirect, incidental, or consequential damages resulting from technical failures or events beyond our control. We will take reasonable steps to address such issues, but no guarantees or warranties are provided regarding resolution.
        </p>

        <h2>5. User Obligations</h2>
        <p>
            By using our services, you agree to:
        </p>
        <ul>
            <li>Provide accurate and complete information when required (e.g., during registration).</li>
            <li>Comply with all applicable laws and regulations while using our services.</li>
            <li>Refrain from engaging in any unauthorized activities, including hacking, introducing malicious software, or circumventing security measures.</li>
            <li>Not copy, reproduce, or distribute any content from our website, including logos, images, or text, without our prior written consent.</li>
        </ul>

        <h2>6. Limitation of Liability</h2>
        <p>
            To the maximum extent permitted by law:
        </p>
        <ul>
            <li>CapitaFlow shall not be liable for any indirect, incidental, consequential, or punitive damages, including loss of data, profits, or business opportunities.</li>
            <li>CapitaFlow's total liability for any claims arising out of or related to your use of our services shall not exceed the amount you paid (if any) for accessing our services in the 12 months preceding the event.</li>
            <li>You acknowledge that all services are provided on an "as-is" and "as-available" basis, with no guarantees or warranties, express or implied.</li>
        </ul>

        <h2>7. Prohibited Activities</h2>
        <p>
            Users are strictly prohibited from:
        </p>
        <ul>
            <li>Copying, reproducing, or stealing any part of the website's content, including images, logos, designs, or text, without explicit permission.</li>
            <li>Reverse engineering, decompiling, or attempting to access the source code of any part of our website or software.</li>
            <li>Using automated tools (e.g., bots or scrapers) to extract data or disrupt services.</li>
            <li>Uploading or transmitting harmful content, such as viruses or malware.</li>
        </ul>

        <h2>8. Termination of Use</h2>
        <p>
            We reserve the right to suspend or terminate your access to our services without prior notice if you violate these Terms or engage in activities that may harm our business or other users. Termination does not waive our rights to pursue legal remedies for violations.
        </p>

        <h2>9. Modifications to Terms</h2>
        <p>
            CapitaFlow reserves the right to update or modify these Terms at any time. Changes will be effective upon posting on this page, and the "Effective Date" will be updated accordingly. Continued use of our services after changes are posted constitutes acceptance of the updated Terms.
        </p>

        <h2>10. Governing Law</h2>
        <p>
            These Terms and your use of our services are governed by the laws of India, without regard to its conflict of law principles. Any disputes shall be subject to the exclusive jurisdiction of the courts in New Delhi.
        </p>

        <h2>11. Contact Us</h2>
        <p>
            For any questions or concerns about these Terms, please contact us: &nbsp;
            <a href="mailto:support@capitaflow.com">support@capitaflow.com</a>
        </p>

        <p>
            We are committed to addressing your concerns in a timely and transparent manner.
        </p>

      </div>
    </div>
  );
};

export default TermsAndConditions;
