import  { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';  // Use Routes instead of Switch in latest version
import Navbar from './navbar/Navbar';
import Home from './home/Home';
import Footer from './footer/Footer';
import Animation from './animations/homeScreen/Animation';
import InvestorRelations from './legal/investorRelations/investorRel';
import { trackPageView } from './scripts/googleAnalytics';
import './App.css';

const TrackPage = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname]);

  return null; // Helper component to track routes
};

function App() {
  console.log("The profile selected is:", process.env.REACT_APP_ENV); // Logs the value of REACT_APP_ENV

  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    // Check if the animation has already been shown
    const hasAnimationPlayed = localStorage.getItem('hasAnimationPlayed');
    if (!hasAnimationPlayed) {
      setShowAnimation(true);
      localStorage.setItem('hasAnimationPlayed', 'true'); // Mark as played
    }
  }, []);

  const handleAnimationEnd = () => {
    setShowAnimation(false); // Hide animation after it ends
  };
  
  return (
    <div className="App">
      {showAnimation ? (
        <Animation onAnimationEnd={handleAnimationEnd} />
      ) : (
        <Router>
          <TrackPage />
          <Navbar />
          <Routes>  {/* Use Routes instead of Switch */}
            {/* Define routes here */}
            <Route path="/" element={<Home />} />  
            <Route path="/investors" element={<InvestorRelations />} />
          </Routes>
          <Footer />
        </Router>
      )}
    </div>
  );
}

export default App;
