import { useEffect } from 'react';
import './privacyPolicy.css'; // Add your styles for the modal

const PrivacyPolicy = ({ isOpen, onClose }) => {
  const effectiveDate = 'November 24, 2024';

  // Disable body scroll when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Disable scroll
    } else {
      document.body.style.overflow = ''; // Enable scroll
    }

    // Cleanup when modal is closed
    return () => {
      document.body.style.overflow = ''; // Reset on unmount
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
      >
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>

           <h1>Privacy Policy</h1>
          <p><strong>Effective Date:</strong> {effectiveDate}</p>
          <p>
              CapitaFlow Fintech Pvt. Ltd. ("CapitaFlow," "we," "our," or "us") is committed to safeguarding your privacy. This Privacy Policy provides a detailed explanation of how we collect, use, share, and protect your information when you access our website or services. It also outlines your rights and how you can exercise them.
          </p>
          <p>
              By using our website or services, you agree to the practices described in this Privacy Policy. If you disagree with any part of this policy, please discontinue use of our website and services immediately.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
              We collect information to provide services effectively and improve user experience. The information we collect includes:
          </p>

          <h3>A. Information You Provide Directly</h3>
          <ul>
              <li><strong>Account Information:</strong> When you register or create an account, we collect details such as your name, email address, phone number, and password.</li>
              <li><strong>Transaction Details:</strong> If you purchase our services, we collect billing details, payment information, and transaction history.</li>
              <li><strong>Customer Support:</strong> Information you provide when contacting us for support, including inquiries, complaints, or feedback.</li>
              <li><strong>Optional Information:</strong> Data shared during surveys, participation in promotions, or voluntary submission of additional details.</li>
          </ul>

          <h3>B. Information Collected Automatically</h3>
          <ul>
              <li><strong>Technical Data:</strong> IP address, browser type, operating system, and device type (e.g., mobile, desktop).</li>
              <li><strong>Usage Data:</strong> Time spent on pages, links clicked, and browsing patterns on our website.</li>
              <li><strong>Location Data:</strong> General location based on your IP address to tailor content and services.</li>
          </ul>

          <h3>C. Information from Third Parties</h3>
          <ul>
              <li><strong>Social Media Platforms:</strong> If you interact with us via platforms like Facebook, LinkedIn, or Google, we may receive information such as your profile details.</li>
              <li><strong>Service Providers:</strong> Data provided by payment processors or analytics services to facilitate transactions or understand user behavior.</li>
              <li><strong>Public Sources:</strong> Information from publicly available sources that may be relevant to our services.</li>
          </ul>
          <h2>2. How We Use Your Information</h2>
          <p>
              The information we collect is used for the following purposes:
          </p>

          <h3>A. Service Delivery</h3>
          <ul>
              <li>To process transactions, set up user accounts, and provide services as requested.</li>
              <li>To personalize your experience by offering tailored content, recommendations, or advertisements.</li>
          </ul>

          <h3>B. Communication</h3>
          <ul>
              <li>To send important notifications, including account updates, security alerts, and service changes.</li>
              <li>To provide customer support and respond to inquiries or complaints.</li>
              <li>To share promotional materials or updates about new products or services (subject to your consent).</li>
          </ul>

          <h3>C. Improvement and Analytics</h3>
          <ul>
              <li>To analyze website usage and improve functionality, layout, and user experience.</li>
              <li>To evaluate the effectiveness of marketing campaigns and advertisements.</li>
          </ul>

          <h3>D. Security and Legal Compliance</h3>
          <ul>
              <li>To detect and prevent fraudulent transactions or unauthorized access to our systems.</li>
              <li>To comply with applicable laws, regulations, and legal processes.</li>
          </ul>
          <h2>3. Sharing Your Information</h2>
          <p>
              We respect your privacy and only share your information under the following circumstances:
          </p>

          <h3>A. With Third-Party Service Providers</h3>
          <p>
              We partner with trusted third-party providers to assist with:
          </p>
          <ul>
              <li>Payment processing and financial transactions.</li>
              <li>Website hosting, data storage, and technical maintenance.</li>
              <li>Marketing, advertising, and user analytics to enhance our services.</li>
          </ul>

          <h3>B. Legal and Regulatory Obligations</h3>
          <ul>
              <li>To comply with laws, regulations, or court orders.</li>
              <li>To cooperate with government authorities or law enforcement agencies when required.</li>
          </ul>

          <h3>C. Business Transfers</h3>
          <ul>
              <li>In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new entity.</li>
          </ul>

          <h3>D. Your Explicit Consent</h3>
          <p>
              We will share your information with other parties only when you provide explicit consent for specific purposes.
          </p>

          <h3>E. Third-Party Links</h3>
          <p>
              Our website may include links to third-party websites or services. We are not responsible for the privacy practices or content of these external platforms. Please review their policies before sharing any personal information.
          </p>
          <h2>4. Cookies and Tracking</h2>
          <p>
              Cookies and similar technologies are used to enhance your experience on our website. These include:
          </p>

          <h3>A. Types of Cookies</h3>
          <ul>
              <li><strong>Essential Cookies:</strong> Required for basic website functionality, such as user authentication.</li>
              <li><strong>Performance Cookies:</strong> Help us understand how users interact with our site and improve its performance.</li>
              <li><strong>Functional Cookies:</strong> Store your preferences, such as language settings or saved sessions.</li>
              <li><strong>Advertising Cookies:</strong> Deliver targeted ads based on your browsing behavior.</li>
          </ul>
          <h3>B. Managing Cookies</h3>
          <p>
              You can control or disable cookies via your browser settings. However, disabling cookies may limit certain features of our website.
          </p>
          <h2>5. Your Rights</h2>
          <p>You have several rights regarding your personal data, including:</p>
          <ul>
              <li><strong>Right to Access:</strong> Request access to the information we hold about you.</li>
              <li><strong>Right to Rectify:</strong> Update or correct inaccurate or incomplete information.</li>
              <li><strong>Right to Erasure:</strong> Request deletion of your data, subject to legal or contractual obligations.</li>
              <li><strong>Right to Restrict Processing:</strong> Limit how your data is used in specific circumstances.</li>
              <li><strong>Right to Data Portability:</strong> Request a copy of your data in a commonly used format for transfer to another provider.</li>
              <li><strong>Right to Object:</strong> Opt out of direct marketing or data processing based on legitimate interests.</li>
              <li><strong>Right to Withdraw Consent:</strong> Revoke your consent for data processing activities at any time.</li>
          </ul>
          <p>
              To exercise your rights, contact us using the details provided below. We may need to verify your identity before processing certain requests.
          </p>
          <h2>6. Changes to This Policy</h2>
          <p>
              We may revise this Privacy Policy periodically to reflect changes in our practices or legal requirements. Significant updates will be communicated through our website or via email. The "Effective Date" will be updated accordingly.
          </p>
          <h2>7. Contact Us</h2>
          <p>
              If you have questions, concerns, or requests related to this Privacy Policy, please contact us: &nbsp;
              <a href="mailto:privacy@capitaflow.com">privacy@capitaflow.com</a>
          </p>
          <p>
              We strive to address your inquiries promptly and transparently.
          </p>

      </div>
    </div>
  );
};

export default PrivacyPolicy;
