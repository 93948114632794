  import  { useState } from 'react';
  import './Home.css';
  import bannerVideo from '../assets/banner/CapitaFlowBusiness.mp4';
  import teamImg from '../assets/about/team.png';
  import investorImg from '../assets/about/investor.png';
  import futureisticImg from '../assets/about/futuristicSystems.png';
  import resilienceImg from '../assets/values/resilience.png';
  import excellenceImg from '../assets/values/excellence.png'
  import visionImg from '../assets/values/visionImg.png';
  import integrityImg from '../assets/values/integrity.png';
  import serviceImg from '../assets/values/serviceImg.png'; 
  import evolveImg from '../assets/values/evolveImg.png'
  import ContactForm from '../contact/contactForm';
  import useLazyLoad from '../scripts/useLazyLoad';
  import CoverAnimation from '../animations/coverAnimation/CoverAnimation';

  function Home(){
    useLazyLoad(); // Initialize lazy loading
    const [showContactForm] = useState(true); // Example condition
    const [showCoverAnimation] = useState(true); // Example condition
    const [videoLoaded, setVideoLoaded] = useState(false); // Track if video has loaded

    const handleVideoLoad = () => {
      setVideoLoaded(true); // Video has successfully loaded
    };

    return (
      <div className="home-container">
        <section className="banner" id="home">
          <video 
            src={bannerVideo} 
            autoPlay 
            loop 
            muted 
            playsInline
            onCanPlayThrough={handleVideoLoad} 
            className={`banner-video ${videoLoaded ? '' : 'hidden'}`}
          >
            Your browser does not support the video tag.
          </video>

          {/* Text always visible and animating on top of the video */}
          <div>
            {showCoverAnimation && <CoverAnimation />}
          </div>
        </section>

        <section className="welcome">
          <h4>We want to revolutionize and streamline your business with our innovative <i>Business Management Suite</i>. 
            Our scalable platform integrates operations and customer interactions, 
             streamlining processes and driving growth for businesses of all sizes. Adaptable and efficient, we empower
              companies to stay competitive and succeed in any market.
          </h4>
        </section>

        <section className="about" id="about">
          <h2>About Us</h2>
          <div className="feature-list">
            <div className="feature-item">
              <img src={teamImg} alt="teamImage" className="Img" />
              <div>
                <h3>Our Team</h3>
                <p>Our team is a group of passionate innovators committed to reshaping business operations. With diverse expertise across technology and industry, we collaborate to deliver cutting-edge solutions that drive efficiency, growth, and success for our clients.
                  We believe in a collaborative, agile approach where each team member brings unique skills and perspectives to the table. From experienced developers and data scientists to strategic thinkers and business leaders, our team is united by a shared vision of transforming businesses through innovation.</p>
              </div>
            </div>
            <div className="feature-item">
            <img src={investorImg} alt="investorImg" className="Img" />
              <div>
                <h3>Investment Opportunities</h3>
                <p><i>CapitaFlow</i> is redefining the way businesses manage their operations by building a scalable, modular platform that integrates seamlessly across industries. As we continue to develop our innovative solution, we are poised to address the growing demand for streamlined business management tools. This presents a unique opportunity for investors to get in at the ground level of a product that is designed to evolve with the needs of modern enterprises.</p>
                </div>
            </div>
            <div className="feature-item">
              <img src={futureisticImg} alt="futureisticImg" className="Img" />
              <div>
              <h3>Future With Us!</h3>
              <p>Partnering with <i>CapitaFlow</i> opens doors to a future of growth and innovation. As we advance together, you'll benefit from our cutting-edge solutions and personalized approach. We're committed to evolving with your needs, offering ongoing support, and creating opportunities for mutual success. Join us in shaping the future and experience the advantages of a collaborative partnership where your goals drive our innovation.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="values">
          <h2>Our Values and Principles - REVISE</h2>
          <div className="feature-list">
            <div className="feature-item">
              <img src={resilienceImg} alt="ValuesImg"/>
              <h3>Resilience</h3>
              <p>We navigate challenges with agility, adapting quickly to changing circumstances and bouncing back stronger to ensure continuous progress.
              </p>
            </div>
            <div className="feature-item">
              <img src={excellenceImg} alt="ValuesImg"/>
              <h3>Excellence</h3>
              <p>Our commitment to excellence drives us to achieve the highest quality standards in every project, delivering superior solutions and exceptional value.</p>
            </div>
            <div className="feature-item">
              <img src={visionImg} alt="ValuesImg"/>
              <h3>Vision</h3>
              <p>We guide our strategy with a clear vision, focusing on long-term goals and anticipating market trends to stay ahead in a competitive landscape.</p>
            </div>
            <div className="feature-item">
              <img src={integrityImg} alt="ValuesImg"/>
              <h3>Integrity</h3>
              <p>We build trust through unwavering honesty and ethical practices, ensuring transparency in all our business dealings and fostering strong relationships.</p>
            </div>
            <div className="feature-item">
              <img src={serviceImg} alt="ValuesImg"/>
              <h3>Service</h3>
              <p>Our dedication to outstanding service means providing exceptional support, personalised care, and a client-first approach to meet and exceed expectations.</p>
            </div>
            <div className="feature-item">
              <img src={evolveImg} alt="ValuesImg"/>
              <h3>Evolve</h3>
              <p>We embrace continuous growth and innovation, always learning and adapting to new ideas, technologies, and challenges to stay ahead of the curve.</p>
            </div>
          </div>
        </section>

        <div>
            {showContactForm && <ContactForm />}
        </div>
      </div>
    );
  };

  export default Home;
