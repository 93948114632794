import ReactGA from 'react-ga4';

export const initializeGA = () => {
  if (process.env.NODE_ENV === 'production') {
    const measurementId = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

    if (measurementId) {
      ReactGA.initialize(measurementId);
      console.log('Google Analytics initialized');
    } else {
      console.warn('Google Analytics Measurement ID is missing.');
    }
  } else {
    console.log('Google Analytics is disabled in development mode');
  }
};

export const trackPageView = (path) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.send({ hitType: 'pageview', page: path });
  }
};

export const trackEvent = (category, action, label) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};
