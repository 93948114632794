import { useEffect } from "react";

const Disclaimer = ({ isOpen, onClose }) =>{

  // Disable body scroll when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Disable scroll
    } else {
      document.body.style.overflow = ''; // Enable scroll
    }

    // Cleanup when modal is closed
    return () => {
      document.body.style.overflow = ''; // Reset on unmount
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return(
    <div className="modal-overlay" onClick={onClose}>
        <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
        >
        <button className="close-btn" onClick={onClose}>
            &times;
        </button>
        <h1>Disclaimer</h1>
        <p><strong>CapitaFlow Fintech Pvt. Ltd.</strong></p>
        <p>
            The content on the website is an intellectual property of CapitaFlow and the use of the same by any user, certificate applicant, subscriber, relying party, or any other party is prohibited except explicitly stated otherwise.
            Modification, reproduction, distribution, commercial exploitation, or display of the content of these pages, without the explicit written permission of CapitaFlow, is not permitted.
        </p>
        <p>    
            Content includes, but is not limited to text, video, graphics, logos, images, audio clips, trademarks, service marks, domain names, designs, software, etc. All of this content is the intellectual property of CapitaFlow and is protected by Indian Intellectual Property Laws.
        </p>
        <p>
            Systematic compilation, collection, arrangement, and display of all contents is not allowed without permission. Content should neither be reconstructed, edited, nor adulterated in such a manner that false information regarding CapitaFlow is conveyed. 
            However, the content may be downloaded or copied for any personal, non-commercial purpose provided that copies retain the copyright and any other proprietary notices contained on the materials.
        </p>
        <p>
            If any information or article published on our website is from any other source, the source is specifically mentioned except for when the rights are expressly granted by the individual or entity who holds intellectual property rights of the content.
        </p>

        <h2>Legal Disclaimer</h2>
        <p> 
            CapitaFlow does not endorse, promote, or is liable for any non-CapitaFlow website, entity, or third-party service mentioned on the website or used as part of our business operations. 
            Users should use their own discretion before engaging with such websites or services. We disclaim all responsibility for any loss, damage, or adverse outcomes arising from their use. 
        </p>
        <p>
            CapitaFlow shall not be held responsible for any failures, errors, or interruptions caused by events beyond its reasonable control, including but not limited to natural disasters, cyberattacks, system failures, or other unforeseen incidents. In such cases, our services may be temporarily or permanently affected without prior notice, and the company is not liable for any resulting inconvenience or damage.
        </p>
        <p>
            For more information, please review our Privacy Policy and Terms and Conditions.
        </p>
        </div>
    </div>
  )
};

export default Disclaimer;

